import React, { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import "../src/pages/CardFormCustom/CardFormCustom.css";

const CardFormCustom = lazy(() =>
  import("./pages/CardFormCustom/CardFormCustom")
);

const ErrorPage = lazy(() => import("./pages/ErrorPage/ErrorPage"));
const SuccessPage = lazy(() => import("./pages/SuccessPayment/SuccessPayment"));

const Routes = () => {
  return (
    <Switch>
      <Suspense fallback={<div className="loadingSpinner"></div>}>
        <Route path="/CardFormCustom" component={CardFormCustom} />
        <Route path="/error/:statuscode" component={ErrorPage} />
        <Route path="/successPage/:statuscode" component={SuccessPage} />
      </Suspense>
    </Switch>
  );
};

export default Routes;
